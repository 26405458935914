/* 
    Created on : 19 juil. 2019, 16:32:39
    Author     : Sylvain
*/

.additional-details-list{
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
}

.additional-details-list>li {
    overflow: hidden;
    margin-top: 2px;
    margin-bottom: 2px;
    border: 1px solid #F3F3F3;
}

.add-d-title {
    font-weight: 600;
    text-transform: uppercase;
    background-color: midnightblue;
    margin: 0px;
    color: white;
}

.add-d-title:hover {
    background-color: gray;
}

.ui-loader {
  display: none !important;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

body {
    height:100%;
    font-family: 'Open Sans', sans-serif; 
    font-weight: 400;
    padding:0;
    margin:0;
    font-size:16px;
    line-height: 28px;
    color: midnightblue; 
    background: white; 
    position: relative;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4{
    font-weight: 400;
    margin-top: 0px;
}

h1, .h1 {
    font-size: 52px;
}
h2, .h2{
    font-size: 36px;
}
h3, .h3{
    font-size: 28px;
}
h4, .h4{
    font-size: 22px;
}
h5, .h5 {
    font-size: 16px;

}
h6, .h6{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}
p {
    font-size: 16px;
    line-height: 1.6180em;
    padding: 0px 0px 0em;
    margin:0;
    margin-bottom: 20px;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
    color: darkgray;
    font-weight: 300;
    line-height: 1;
}
h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
    font-size: 60%;
}
h1 .subtitle{
    display: block; 
    line-height: 40px;
    margin: 15px 0 30px;
}

a {
    color: midnightblue;
    outline: 0;
    font-weight: bold;
} 
a:hover {
    text-decoration: none;
    color: slateblue;
}
a:focus {
    color: darkgray;
    text-decoration: none;
}

input {
    outline: 0;
    border: none;
    width: 100%;
    height: inherit;
    color: midnightblue;
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 3px;
}
button {
    border-radius: 0px !important;
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    outline: 0;
    outline-offset: 0;
    border: none;
    overflow: visible;
}  
button,
select {
    text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
button.btn i.fa {
    margin-top: 2px;
    margin-left: 2px;
}
input {
    line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.btn:focus,
.btn:hover,
.btn:active{
    outline: 0;
}
.btn{
    border-width: 2px;
    background-color: black;
    font-weight: 400;
    /*opacity: 0.8;*/
    padding: 8px 16px;
}
.btn:hover{ 
    opacity: 1;
    background-color: slateblue;
}
.input-lg{
    height: 56px;
    padding: 10px 16px;
}
.btn:active, .btn.active {
    background-image: none;
    box-shadow: none;

}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    opacity: 0.45;
}
.btn-primary {
    color: white;
    background-color: midnightblue;
    border-color: midnightblue;
    border-radius: 1px;
    padding: 10px 20px;
    font-weight: 600;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,.btn-primary:active:focus, .open > .dropdown-toggle.btn-primary {
    color: white;
    background-color: slateblue;
    border-color: slateblue;
    font-weight: 600;
}
.btn-default {
    border-radius: 1px;
    padding: 10px 20px;
    border: 1px solid midnightblue;
    color: black;
    background-color: midnightblue;
    border-color: lightgray;
    font-weight: 600;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
    color: black;
    background-color: midnightblue;
    border-color: lightgray;
    font-weight: 600;
}

.open > .dropdown-toggle.btn-default {
    color: darkgray;
    background-color: white;
    border-color: lightgray;
}

.bootstrap-select button.dropdown-toggle{
    background: rgba(255, 255, 255, 0.35) none repeat scroll 0% 0%;
    border-radius: 1px;
    height: 40px;
    width: 100%;
    display: block;
    font-size: 13px;
    line-height: 1.42857;
    color: dimgray;
    background-color: white;
    background-image: none;
    border: 1px solid lightgray;
    box-shadow: 0px 1px 1px rgba(169, 169, 169, 0.1) inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    font-weight: 300;
} 
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}
.btn-group.open .dropdown-toggle {
    box-shadow: none;
}
.bootstrap-select .dropdown-menu {
    margin: 0px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    box-shadow:0px 5px 15px rgba(0, 0, 0, 0.176);
    border-top: none;
}
.tab-content .bootstrap-select button.dropdown-toggle {
    width: 100%;                              
}
.tab-content .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}
.bootstrap-select .dropdown-toggle:focus {
    outline: none!important;
}
:focus {
  outline: none!important;
}

.navbar-default .navbar-nav > li > a{
    color: midnightblue;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
}

.navbar-default .main-menu > .dropdown > a {
    padding-top: 0px;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    color: darkgray;
    background-color: transparent;
}
.navbar-nav > li > a {
    padding-right: 20px;
    padding-left: 20px;
}
.top-menu {
    cursor: default;
    margin-bottom: 15px !important;
}

.navbar-default .navbar-nav > li > a:hover:after {
    width: 100%;
}

.navbar-nav.navbar-right:last-child{
    margin-right: 10px
}
.navbar-brand {

}
.main-nav {
    padding-top: 10px;
}

.button.navbar-right {
    padding-top: 10px;
}
.nav-button {
    background-color: lightgray;
    border: medium none;
    color: white;
    font-size: 16px;
    padding: 8px 20px;
}
.nav-button.login{
    background-color: midnightblue;
}
.nav-button:hover{
    background-color: slateblue;

}
.navbar{
    margin-bottom: 0px;
}
.navbar-default {
    background-image: none;
    background-color: white;
    background-repeat: repeat-x;
    border-radius: 4px;
    box-shadow: none;
}
.navbar .container{
    padding-top: 20px
}
.navbar-default {
    background-image: none;
    background-color: white;
    background-repeat: repeat-x;
    border-radius: 0px;
    box-shadow: none;
    border-left: none;
    border-right: none;
}
.nav-tabs > li > a{
    color: gray;
    font-weight: 300;
    border-width: 4px;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border-color: transparent transparent midnightblue;
    border-style: solid;
    border-width: 4px;
    color: gray;
    cursor: default;
    font-weight: 700;
    outline: none;
}

/* Menu */

.yamm .nav,
.yamm .collapse,
.yamm .dropup,
.yamm .dropdown {
    position: static;
}
.yamm .container {
    position: relative;
}
.yamm .dropdown-menu {
    left: auto;
} 
.yamm .yamm-content {
    padding: 20px 30px;
}
.yamm .dropdown.yamm-fw .dropdown-menu {
    left: 0;
    right: 0;
}
.yamm .dropdown-menu {   
    background-color: white; 
    border: 0.3px solid rgba(227, 227, 227, 0.22);
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.176);
}
.ymm-sw .dropdown-menu {
    position: absolute;
    top: 101%;
    left: 0px;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: inherit;
    margin: inherit;
    list-style: outside none none;
    font-size: inherit;
    background-color: white;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    box-shadow: 0px 0.5px 1px #CECECE;
    background-clip: padding-box;
}
.ymm-sw .dropdown-menu li{
    clear: both;
    /*height: 50px;*/
}
.navbar-default .ymm-sw .navbar-nav a::after {
    bottom: 5px;
    background-color: white;
}
.yamm .ymm-sw  .dropdown-menu {
    left: auto;
    right: auto;
}
.navbar .yamm-content h5 {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: dotted 1px #555555;
}
.navbar .yamm-content ul {
    margin: 0;
    padding: 0;
}
.navbar .yamm-content ul li {
    list-style-type: none;
    border-bottom: solid 1px #eeeeee;
    text-transform: uppercase;
    padding: 4px 0;
}
.navbar .yamm-content ul li a {
    color: gray;
    font-size: 14px;
    display: block;
    font-weight: 300;
}
.navbar .yamm-content ul li a:hover {
    color: midnightblue;
    text-decoration: none;
}
.navbar .yamm-content .banner {
    margin-bottom: 10px;
}

/* Slider */

.slider-area{
    position: relative;
    height: 100%;
}
#bg-slider .item img{
    width: 100%;
    height: 100%;
}

.slider-content{
    position: absolute;
    text-align: center;
    color: white;
    width: 100%;
}
.slider-content h2 {
    font-size: 40px; 
    margin-bottom: 20px;
    text-transform: uppercase;
    color: midnightblue;
    font-weight: 500;
}
.slider-content p {
    font-size: 14px;
    margin-bottom: 60px;
    color: gray;
}

.slider .owl-theme .owl-controls {
    margin-top: 0px;
    position: relative;
    text-align: center;
}
.slider .owl-pagination {
    bottom: 280px;
    position: absolute;
    right: 10px;
    width: 40px;
    z-index: 9999;
}
.slider .owl-theme .owl-controls .owl-page span{
    background: #FFC600
}

/* Top Header */

.progress-thin{
    height: 2px;
}

.header{
    padding-top: 10px;
}

.header-call p {
    color: midnightblue;
}
.header-call p span {
    margin-left: 20px;
    font-weight: bold;
}
.header-phone i, .header-mail i {
    margin-right: 10px;
    font-size: 20px;
    position: relative;
    bottom: -3px;
    font-weight: bold;
}

.header-social ul li a{
    color: midnightblue;
    font-size: 14px

}
.header-social ul li a:hover{
    color: slateblue;
}

/* Page header */

.page-head {
    height: 157px;
    overflow: hidden;
    position: relative;
}
.page-head img {
    width: 100%;
    height: 157px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}
.page-head-content {
    padding-top: 52px;
    padding-bottom: 52px;
}
.page-head-content .page-title {
    margin: 0px;
    padding: 10px 16px;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white;
    
}
.content-area{
    background-color: white; padding-bottom: 55px;
}
.layout-switcher a{
    font-size: 25px;
    color:gray;
}
.layout-switcher a:hover{     
    color:midnightblue;
}
.layout-switcher .active{
    color:midnightblue;
}
.layout-switcher {
    padding: 0px 10px;
    text-align: right;
}

.form-inline input[type="submit"]:hover {
    background: rgb(126, 126, 126) none repeat scroll 0% 0%;
}
fieldset { 
    border: 1px solid lightgray;
    margin-bottom: 10px;
    padding: 10px;
}

/* header */

.logo {
    padding: 0px;
    position: absolute;
    z-index: 1;
}

.header-social .fa {
    font-size: 16px;  
}

.header-social ul {
    padding-top: 0px;
}

.header-social li {
    padding-left: 20px;
    padding-right: 0px;
}

.top-menu {
    padding-top : 0px !important;
}

.locales {
    margin-right: 0px;
}

.locale {
    color: midnightblue;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
}

footer {
    padding-top: 20px;
    padding-bottom: 20px;
}

footer table .footer-link {
    padding-left: 20px;
}

.frame-virtual-tour {
    width: 100%;
    height: 250px;
    display: block;
}

.full-width {
    width: 100%;
}

.custom-combobox {
    position: relative;
    display: inline-block;
}

.custom-combobox-toggle {
    position: absolute !important;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    padding: 0;
}

.custom-combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: initial;
}

.module {
  width: 100%;
  overflow: hidden;
  height: 106px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}


.icon-fullscreen {
    width: 30px;
    height: 30px;
}

.slider-style2 img {
    object-fit: contain;
}

.slider-text {
    position: absolute;
}

.full-picture-background {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

.full-picture {
/*    position: fixed; */
    width: 80%;
    /*top: 100px;*/
/*    text-align: center;*/
    display: block;
/*    overflow: auto;*/
/*    z-index: 100;*/
    margin: auto;
    border: 5px solid white;
}

.picture img {
    cursor: pointer;
}

.picture-simple img:hover {
    opacity: 1;
}

.pictures .picture img {
    width: 100%;
}

.with-small-margin-top{
    margin-top: 10px;
}

.with-small-margin-bottom {
    margin-bottom: 10px;
}

.with-margin-bottom {
    margin-bottom: 20px;
}

.with-big-margin-bottom {
    margin-bottom: 40px;
}

.with-margin-top {
    margin-top: 20px;
}

.without-margin-top {
    margin-top: 0px;
}

.without-padding {
    padding: 0px;
}

.without-margin {
    margin: 0px;
}

.without-margin-side {
    margin-left: 0px;
    margin-right: 0px;
}

h4 {
    margin-top: 0px;
}
.contact {
    font-weight: bold;
}

.contact i {
    margin-right: 10px;
    font-size: 20px;
    position: relative;
    bottom: -3px;
}

.nav-button:hover {
    color: white;
}
.navbar-nav>li>a:hover {
    color: slateblue !important;
}

.page-title {
    padding-top: 55px;
}
.page-title h5{
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0
}
.page-title h2 {
    margin: 10px 0px;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 4px;
}
.page-title p {
    font-size: 18px;
    padding: 10px 20% 25px;
    line-height: 27px;
}

/*Tools CSS*/
.p0{
    padding: 0px;
}
.overflow{
    overflow: hidden;
}
.mar-l-20{
    margin-left: 20px;
}
.mar-r-20{
    margin-right: 20px;
}
.box {
    background: white none repeat scroll 0% 0%;
    margin: 0px 0px 30px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.box-two {
    background: white none repeat scroll 0% 0%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.dot-hr{
    border-bottom: 1px dotted lightgray;
    margin-bottom: 10px;
}
.color{
    color:dimgray;
}
.strong{
    font-weight: 500;
}
.padding-top-25{
    padding-top: 25px;
}
.padding-top-40{
    padding-top: 40px;
}
.padding-bottom-40{
    padding-bottom: 40px;
}
.pr0 {
    padding-right: 0px !important;
}
.pl0 {
    padding-left:0px !important;
}
.pl-10 {
    padding-left: 10px !important;
}
.pr-10 {
    padding-right: 10px !important;
}

.padding-top-15{
    padding-top:15px;
}
.padding-bottom-15{
    padding-bottom:15px;
}
.pr-30 {
    padding-right: 30px !important;
}
.p-b-15{
    padding-bottom: 15px;
}
.padding-5{
    padding: 5px
}
.center{
    text-align: center;
}

.right {
    text-align: right;
}
.left {
    text-align: left;
}
.clearfix {
    clear: both;
}
.s-property-title {
    margin-bottom: 15px;
}
.s-property-title::after {
    background-color: lightgray;
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    margin: 3px 0px 0px 2px;
}
.space1{
    height: 20px;
}
.clear{ 
    clear: both;
    overflow: hidden;
}
.border-btn{
    border: 1.5px solid;
    background: transparent none repeat scroll 0% 0%;
    border-radius: 0px;
    padding: 9px 25px;
    margin-top: 10px;

}
.item-entry a:hover {
    color: slateblue;
}
.search-form .search-toggle {
    margin-top: 20px;
    color: gray;
    border-top: 1px solid #EAEAEA;
    padding: 15px;
}
.search-form .form-inline .form-group { 
    width: 235px;
    text-align: left;
    letter-spacing: 0.5px;
    vertical-align: top;
}
.search-form .form-inline .form-search { 
    /*width: 426px;*/
    display: block;
}
.search-main {
    max-width:527px;
    margin-left:auto;
    margin-right:auto;
}
.search-main-bar {
    margin: 0 45px;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}
.slider-selection { 
    background: midnightblue;
}
.search-form .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: white;
    text-decoration: none;
    background-color: midnightblue;
    outline: 0px none;
}
.search-form .dropdown-menu > li > a {
    display: block;
/*    padding: 9px 20px;*/
    clear: both;
    font-weight: 400;
    line-height: 1.42857;
    color: black;
    white-space: nowrap;
}
.search-form .property-geo, .search-form .property-district {
    width: 100%;
}

.search-form .property-district {
    margin-bottom: 13px;
}

.social ul {
    padding: 0px;
    list-style: outside none none;
}
.social li {
    display: inline-block;
    padding-right: 0.3em;
    padding-bottom: 0.3em;
}
.social  ul li a:hover {
    color: midnightblue;
    background-color: white;
}
.social  ul li a:hover i{
   color: midnightblue;
}
.social ul li a{
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid midnightblue;
    transition: all 0.8s ease 0s;
    color: midnightblue;
    background-color: midnightblue;
}
.social ul li a i{
    color: white;
    font-size: 20px;
    padding: 10px;
}
/* Preloader */

#preloader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:white; /* change if the mask should have another color then white */
    z-index:99; /* makes sure it stays on top */
}

#status {
    width:200px;
    height:200px;
    position:absolute;
    left:50%;  
    top:50%;  
    background-image:url(../../img/loading.svg);  
    background-repeat:no-repeat;
    background-position:center;
    margin:-100px 0 0 -100px; 
}
#map {
    height: 300px;
}

@media (min-width: 768px) {
.locale {
    padding-top: 15px;
    padding-bottom: 15px;
}
footer table {
    float: right;
}
.navbar-default .navbar-nav > li > .top-menu.active:after{
    background-color: midnightblue;
    bottom: -16px;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease 0s;
    width: 100%;
    z-index:1;
}
.navbar-default .navbar-nav > li > .top-menu::after {
    background-color: midnightblue;
    bottom: -16px;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease 0s;
    width: 0%;
    z-index:1;
}
.header-phone, .header-mail, .header-social {
    display: inline-block;
}

.header-phone {
    padding-left: 140px;
    float: left;
}

.header-mail {
    float: left;
    padding-left: 30px;
}

.header-social {
    float: right;
}

.desc-social {
    text-align: right;
}
}

.main-nav {
    padding-top: 30px;
}

.locales {
    padding-top: 26px;
}

@media (min-width: 992px) {
.header-phone {
    padding-left: 170px;
}
.contact-phones {
    padding: 0;
}
.comparison h3:not(.first-titles) {
    display: none;
}
.slider-content{
    top: 25%;
}
}